<template>
  <a-layout class="admin-enterprise-con my-layout">
    <!-- <a-layout-header class="header">
      <div class="logo" />

    </a-layout-header> -->
    <a-layout>
      <a-layout-sider
        :width="280"
        class="admin-enterprise-layout-sider"
        breakpoint="lg"
        :collapsed-width="0"
        v-model:collapsed="collapsed"
        :trigger="null"
        @breakpoint="onBreakpoint">
        <a-menu
          v-model:selectedKeys="siderSelectedKeys"
          mode="inline"
          @select="handleSiderSelect"
          :inlineIndent="16"
        >
          <div class="admin-enterprise-layout-sider-inner-wrap flex flex-wrap flex-a-c-sb">
            <div class="menu-group-wrap flex-shrink0">
              <div class="my-menu-group" v-if="false">
                <!-- <div class="my-menu-group-title">基础信息</div> -->
                <a-menu-item class="my-menu-group-title" disabled>{{$t('enterprise.menu.basicInformation')}}</a-menu-item>
                <a-menu-item :key="enterprisePath.ENTERPRISE_INFO">
                  <UserOutlined class="menu-item-icon" />
                  {{$t('enterprise.menu.enterpriseInformation')}}
                </a-menu-item>
                <div class="my-divider"></div>
              </div>

              <div class="my-menu-group">
                <!-- <div class="my-menu-group-title">人员</div> -->
                <!-- <a-menu-item class="my-menu-group-title" disabled>{{$t('enterprise.menu.user')}}</a-menu-item> -->
                <a-menu-item class="my-menu-group-title" disabled>{{$t('enterprise.enterpriseSettings')}}</a-menu-item>
                <!-- <a-menu-item :key="enterprisePath.ORGANIZATION_MEMBERS">
                  <UserOutlined class="menu-item-icon" />
                  {{$t('enterprise.menu.organizationAndMembers')}}
                </a-menu-item>
                <a-menu-item :key="enterprisePath.ENTERPRISE_ROLE_MANAGEMENT">
                  <UserOutlined class="menu-item-icon" />
                  {{$t('enterprise.menu.enterpriseRoleManagement')}}
                </a-menu-item>
                <a-menu-item :key="enterprisePath.PROJECT_ROLE_MANAGEMENT">
                  <UserOutlined class="menu-item-icon" />
                  {{$t('enterprise.menu.projectRoleManagement')}}
                </a-menu-item> -->
                <a-menu-item :key="enterprisePath.MEMBER_MANAGEMENT">
                  <UserOutlined class="menu-item-icon" />
                  {{$t('enterprise.menu.memberManagement')}}
                </a-menu-item>
                <!-- <div class="my-divider"></div> -->
              </div>

              <div class="my-menu-group" v-if="false">
                <!-- <div class="my-menu-group-title">应用</div> -->
                <a-menu-item class="my-menu-group-title" disabled>{{$t('enterprise.menu.application')}}</a-menu-item>
                <a-menu-item :key="enterprisePath.PROJECT_APPS_MANAGEMENT">
                  <UserOutlined class="menu-item-icon" />
                  {{$t('enterprise.menu.projectApplicationManagement')}}
                </a-menu-item>
                <div class="my-divider"></div>
              </div>

              <div class="my-menu-group" v-if="false">
                <!-- <div class="my-menu-group-title">项目</div> -->
                <a-menu-item class="my-menu-group-title" disabled>{{$t('enterprise.menu.project')}}</a-menu-item>
                <a-menu-item :key="enterprisePath.PROJECT_MANAGEMENT">
                  <UserOutlined class="menu-item-icon" />
                  {{$t('enterprise.menu.projectManagement')}}
                </a-menu-item>
              </div>
            </div>

            <!-- <div class="enterprise-info-wrap flex-shrink0" v-if="!isBreakpoint">

              <div class="enterprise-info-item">
                <div class="enterprise-info-title">{{$t('enterprise.menu.projectCapacity')}}：86G / 130G</div>
                <a-slider class="my-slider" v-model:value="projectCapacity" disabled />
              </div>
              <div class="enterprise-info-item">
                <div class="enterprise-info-title">{{$t('enterprise.menu.personnelQuota')}}：102 / 500人</div>
                <a-slider class="my-slider" v-model:value="personnelQuota" disabled />
              </div>
            </div> -->
          </div>

        </a-menu>
      </a-layout-sider>
      <a-layout class="admin-enterprise-layout-conten-wrap" :style="isBreakpoint ? '' : 'padding-left: 16px;'">
        <a-layout-content class="admin-enterprise-layout-content">
          <div class="my-trigger-wrap center" v-if="isBreakpoint">
            <menu-unfold-outlined
              v-if="collapsed"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
            <menu-fold-outlined
              v-else
              class="trigger"
              @click="() => (collapsed = !collapsed)" />
          </div>
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script>
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons-vue'
import { defineComponent, onMounted, reactive, toRefs, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { admin } from '@/router/path'
const { enterprise } = admin
export default defineComponent({
  components: {
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined
  },

  setup () {
    const router = useRouter()
    const store = useStore()
    const { t } = useI18n()
    // console.log(router)
    const state = reactive({
      siderSelectedKeys: [enterprise.ENTERPRISE_INFO],
      projectCapacity: 66,
      personnelQuota: 20,
      isBreakpoint: false,
      collapsed: false,
      enterprisePath: enterprise
    })

    const handleSiderSelect = ({ key }) => {
      switch (key) {
        /* 基础信息 */
        case enterprise.ENTERPRISE_INFO:
          // 企业信息
          break

        /* 人员 */
        case enterprise.ORGANIZATION_MEMBERS:
          // 组织与成员
          break
        case enterprise.ENTERPRISE_ROLE_MANAGEMENT:
          // 企业角色管理
          break
        case enterprise.PROJECT_ROLE_MANAGEMENT:
          // 项目角色管理
          break

        /* 应用 */
        case enterprise.PROJECT_APPS_MANAGEMENT:
          // 项目应用管理
          break

        /* 项目 */
        case enterprise.PROJECT_MANAGEMENT:
          // 项目管理
          break
        default:
          break
      }
      router.replace(key)
    }

    const onBreakpoint = (val) => {
      state.isBreakpoint = val
    }

    onMounted(() => {
      store.dispatch('setAdminNavtitleAction', t('enterprise.enterpriseSettings'))
      state.siderSelectedKeys = [router.currentRoute.value.name]
    })

    watchEffect(() => {
      if (store.state.locale) {
        store.dispatch('setAdminNavtitleAction', t('enterprise.enterpriseSettings'))
      }
    })

    return {
      ...toRefs(state),
      onBreakpoint,
      handleSiderSelect
    }
  }

})
</script>

<style lang="less">
.admin-enterprise-con {
  width: 100%;
  height: 100%;
  background: #EDEDED;
  position: relative;
  .admin-enterprise-layout-sider {
    // height: calc(100% - 10px);
    // margin-top: 10px;
    width: 280px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2)!important;
    border-radius: 4px;
    overflow: hidden;
    .ant-menu {
      height: 100%;
      position: relative;
      .admin-enterprise-layout-sider-inner-wrap {
        padding-bottom: 12px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 6px;
        .menu-group-wrap {
          width: 100%;
          .my-menu-group {
            width: 100%;
            position: relative;
            margin-bottom: 16px;
            width: 100%;
            .my-menu-group-title {
              font-size: 12px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.4)!important;
              line-height: 17px;
              // padding: 12px 16px;
            }
            .menu-item-icon {
              margin-right: 8px;
            }
            .my-divider {
              width: calc(100% - 32px);
              height: 1px;
              background-color: rgba(0, 0, 0, 0.1);
              margin: 16px auto 0;
            }
            .ant-menu-item {
              height: 48px;
              line-height: 48px;
              margin: 0;
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.8);
            }
            .ant-menu-item-selected {
              color: #209FFD;
            }
          }
        }
        .enterprise-info-wrap {
          padding: 0 16px;
          width: 100%;
          .enterprise-info-item {
            margin-top: 16px;
            .enterprise-info-title {
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.7);
              line-height: 20px;
            }
            .my-slider {
              margin: 8px 0 0!important;
              border-radius: 4px;
              .ant-slider-rail {
                height: 8px;
                border-radius: 4px;
              }
              .ant-slider-track {
                background-color: #209FFD!important;
                height: 8px;
                border-radius: 4px;
              }
              .ant-slider-handle {
                display: none;
              }
            }
          }

        }
      }

    }

  }
  .admin-enterprise-layout-conten-wrap {
    // padding: 10px 0 0px 0;
    position: relative;
    .admin-enterprise-layout-content {
      padding: 24px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      min-width: 300px;
    }
  }
  .my-trigger-wrap {
    position: absolute;
    width: 28px;
    height: 20px;
    line-height: 21px;
    cursor: pointer;
    left: 0;
    top: 0;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0 4px 4px 0;
    color: rgba(0, 0, 0, 0.6);
    z-index: 9;
    background-color: #fff;
  }
}
</style>
